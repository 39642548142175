

























































































import api from "@/api"; //ABP API接口
import { Vue, Component, Ref } from "vue-property-decorator";
import AgileAuditTag from "@/components/AgileAuditTag/index.vue";

import {
  ServiceApplyDtoPagedResultDto
} from "@/api/appService";
import PagedTableView from "@/components/PagedTableView/index.vue";
import AbSelect from "@/components/AbSelect/index.vue";
import moment from "moment";
import ExportButton from "@/components/ExportButton/index.vue";
@Component({
  name: "RoleList",
  components: {
    PagedTableView,
    AbSelect,
    AgileAuditTag,
    ExportButton
  },
})
export default class ServiceApplyList extends Vue {
  @Ref() readonly pagedTableView!: any;
  applyId = 0;
  tableItems: ServiceApplyDtoPagedResultDto[] = [];

  queryForm: any = {
    title: undefined,
    startDate: undefined,
    endDate: undefined
  }

  // 获取表数据
  async fetchData(params: any) {
    return await api.serviceMaintainService.getAll(params);
  }

  handleMenu(e: any) {
    switch (e.type) {
      case "detail": {
        this.jumpDetail(e.index, e.item);
        return;
      }
      case "research": {
        this.handleResearch(e.index, e.item);
        return;
      }
    }
  }

  // 报名签到明细
  jumpDetail(index: number, row: any) {
    this.$router.push({
      name: "serviceApply-SignUpDetail",
      params: { id: row.id!.toString() },
    });
  }
}
